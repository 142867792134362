<template>
  <div class="chartBox">
    <div class="itemBox">
      <div class="item">
        <span class="item_title">今年处理文件数量</span>
        <dv-digital-flop :config="config1" style="height:35px;" />
      </div>
      <div class="item">
        <span class="item_title">本月处理文件数量</span>
        <dv-digital-flop :config="config2" style="height:35px;" />
      </div>
      <div class="item">
        <span class="item_title">今日处理文件数量</span>
        <dv-digital-flop :config="config3" style="height:35px;" />
      </div>
      <div class="item">
        <span class="item_title">今日上传患者数量</span>
        <dv-digital-flop :config="config4" style="height:35px;" />
      </div>
      <div class="item">
        <span class="item_title">今日患者处理数量</span>
        <dv-digital-flop :config="config5" style="height:35px;" />
      </div>
      <div class="item">
        <span class="item_title">今日剩余处理患者</span>
        <dv-digital-flop :config="config6" style="height:35px;" />
      </div>
    </div>
    <div class="ranking">
      <div class="chartTitle">
        <icon class="title_icon" name="align-left"></icon>
        <span class="title_font">TOP10医院近三十天成功数量</span>
      </div>
      <dv-scroll-ranking-board :config="ranking" style="height:85%;" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chart1',
  data() {
    return {
      config1: null,
      config2: null,
      config3: null,
      config4: null,
      config5: null,
      config6: null,
      ranking: null
    }
  },
  props: {
    chart2Data: Object
  },
  watch: {
    chart2Data: {
      // handler(newData) {
      handler() {
        this.config1 = {
          number: [270432],
          // number: [newData.yearProcessNums],
          style: {
            fontSize: 24,
            fill: '#3de7c9',
            textAlign: 'left'
          }
        }

        this.config2 = {
          number: [9014],
          // number: [newData.monthProcessNums],
          style: {
            fontSize: 24,
            fill: '#3de7c9',
            textAlign: 'left'
          }
        }

        this.config3 = {
          number: [751],
          // number: [newData.dayProcessNums],
          style: {
            fontSize: 24,
            fill: '#3de7c9',
            textAlign: 'left'
          }
        }

        this.config4 = {
          number: [1205],
          // number: [newData.dayPatNums],
          style: {
            fontSize: 24,
            fill: '#3de7c9',
            textAlign: 'left'
          }
        }

        this.config5 = {
          number: [406],
          // number: [newData.dayPatProcessNums],
          style: {
            fontSize: 24,
            fill: '#3de7c9',
            textAlign: 'left'
          }
        }

        this.config6 = {
          number: [799],
          // number: [newData.dayPatSurplusNums],
          style: {
            fontSize: 24,
            fill: '#3de7c9',
            textAlign: 'left'
          }
        }

        let rankingData = [{
          name: '龙华医院',
          value: '3809'
        },{
          name: '北京中医药大学东直门医院',
          value: '3612'
        },{
          name: '广东省中医院',
          value: '3256'
        },{
          name: '广州中医药大学第一附属医院',
          value: '3001'
        },{
          name: '深圳平乐骨伤科医院',
          value: '2959'
        },{
          name: '上海市中医院',
          value: '2764'
        },{
          name: '河南省洛阳正骨医院',
          value: '2677'
        },{
          name: '陕西中医药大学附属医院',
          value: '2513'
        },{
          name: '浙江中医药大学附属杭州市中医院',
          value: '2498'
        },{
          name: '苏州市中医医院',
          value: '2465'
        }];
        this.ranking = {
          data: rankingData,
            // data: newData.rankingData,
            waitTime: 4000,
            unit: "个"
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    
  }
}
</script>

<style lang="scss" scoped>
.chartBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  .itemBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .item {
    width: 32.5%;
    height: 60px;
    background-color: rgba(19, 25, 47, 0.6);
    box-sizing: border-box;
    padding: 6px 12px;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 8px;
    .item_title {
      color: #257dff;
      font-size: 14px;
    }
  }
  // .item:not(:last-of-type) {
  //   margin-right: 10px;
  // }
  .ranking {
    flex: 1;
    height: 0;
    background-color: rgba(19, 25, 47, 0.6);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
  }
}
</style>