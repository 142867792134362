<template>
  <div class="chartBox">
    <div class="chartTitle">
      <icon class="title_icon" name="align-left"></icon>
      <span class="title_font">TOP10医院总成功数量</span>
      <dv-decoration-1 class="dv-dec-1" />
    </div>
    <dv-scroll-board :config="config" style="width:100%;height:85%" />
  </div>
</template>

<script>
export default {
  name: 'Chart3',
  data() {
    return {
      config: null
    }
  },
  props: {
    chart3Data: Array
  },
  watch: {
    chart3Data: {
      handler(newData) {
        this.config = {
          header: ["医院", "成功数"],
          data: newData,
          rowNum: 6, //表格行数
          headerHeight: 35,
          headerBGC: "#0f1325", //表头
          oddRowBGC: "#0f1325", //奇数行
          evenRowBGC: "#171c33", //偶数行
          index: true,
          columnWidth: [50, 320, 100],
          align: ["center"]
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.chartBox {
  position: relative;
  height: 100%;
  background-color: rgba(19, 25, 47, 0.6);
  border-radius: 10px;
  padding: 10px;
  .chartTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    .title_icon {
      color: #5cd9e8
    }
    .title_font {
      margin: 0 5px;
    }
    .dv-dec-1 {
      width: 80px;
      height: 18px;
    }
  }
}
</style>