<template>
  <div class="container">
    <dv-loading v-if="loading">Loading...</dv-loading>
    <dv-full-screen-container v-else class="full_container">
    <el-row class="headerBox">
      <el-col :span="8"><dv-decoration-10 style="width: 100%; height:10px;"/></el-col>
      <el-col :span="2"><dv-decoration-8 :color="['#568aea', '#000000']" style="width:100%;height:50px;margin-top:3px;" /></el-col>
      <el-col :span="4"><div class="title"><span>大数据可视化平台</span><dv-decoration-6 class="title-bototm" :reverse="true" :color="['#50e3c2', '#67a1e5']" style="width:200px;height:8px;"/></div></el-col>
      <el-col :span="2"><dv-decoration-8 :color="['#568aea', '#000000']" style="width:100%;height:50px;transform: rotateY(180deg);margin-top:3px;" /></el-col>
      <el-col :span="8" style="text-align: right;"><dv-decoration-10 style="width: 100%; height:10px;transform: rotateY(180deg);"/><span class="date">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span></el-col>
    </el-row>
    <el-row :gutter="10" style="margin-bottom: 12px;height: 34%">
      <el-col :span="7" style="height: 100%">
        <div style="height: 100%">
          <dv-border-box-12 style="padding:12px">
            <Chart1 v-if="isData" :chart1Data="chart1Data" />
          </dv-border-box-12>
        </div>
      </el-col>
      <el-col :span="10" style="height: 100%">
        <div style="height: 100%">
          <Chart2 v-if="isData" :chart2Data="chart2Data" />
        </div>
      </el-col>
      <el-col :span="7" style="height: 100%">
        <div style="height: 100%">
          <dv-border-box-13 style="padding:12px">
            <Chart3 v-if="isData" :chart3Data="chart3Data" />
          </dv-border-box-13>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="height: 43%">
      <el-col :span="12" style="height: 100%">
        <div  style="height: 100%">
          <dv-border-box-13 style="padding:12px">
            <Chart4 v-if="isData" :Chart4Data="Chart4Data" />
          </dv-border-box-13>
        </div>
      </el-col>
      <el-col :span="12" style="height: 100%">
        <div  style="height: 100%">
          <dv-border-box-12 style="padding:12px">
            <Chart5 v-if="isData" :Chart5Data="Chart5Data" />
          </dv-border-box-12>
        </div>
      </el-col>
    </el-row>
    </dv-full-screen-container>
  </div>
</template>

<script>
import drawMixin from "../common/drawMixin";
import { formatTime } from '../utils/index.js'
import Chart1 from '../components/Chart1'
import Chart2 from '../components/Chart2'
import Chart3 from '../components/Chart3'
import Chart4 from '../components/Chart4'
import Chart5 from '../components/Chart5'

export default {
  name: 'Index',
  mixins: [drawMixin],
  data() {
    return {
      loading: true,
      timing: null,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      isData: false,
      chart1Data: null,
      chart2Data: null,
      chart3Data: null,
      Chart4Data: null,
      Chart5Data: null
    };
  },
  components: {
    Chart1,
    Chart2,
    Chart3,
    Chart4,
    Chart5
  },
  created() {
    this.getData();
  },
  mounted() {
    this.timeFn();
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH:mm:ss')
        this.dateYear = formatTime(new Date(), 'yyyy年MM月dd日')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    getData() {
      this.$axios.get("/api/api/web/dataview/getdataview").then((res) => {
        this.loading = false;
        this.isData = true;
        let data = res.Data;
        this.chart1Data = {
          maleNums: data.maleNums,
          femaleNums: data.femaleNums
        };

        let hospitalList = data.hospitalList.map(item => {
          return {
            name: item.name,
            value: item.nums
          }
        })
        this.chart2Data = {
          yearProcessNums: data.yearProcessNums,
          monthProcessNums: data.monthProcessNums,
          dayProcessNums: data.dayProcessNums,
          dayPatNums: data.dayPatNums,
          dayPatProcessNums: data.dayPatProcessNums,
          dayPatSurplusNums: data.dayPatSurplusNums,
          rankingData: hospitalList
        }

        let allHospitalList = data.allHospitalList.map(item => {
          return [item.name, `<span  class='colorGrass'>${item.nums}</span>`]
        })
        this.chart3Data = allHospitalList;

        let category = data.lastTrendsList.map(item => {
          return item.name
        })
        let barData = data.lastTrendsList.map(item => {
          return item.nums
        })
        this.Chart4Data = {
          category: category,
          barData: barData
        };

        let category1 = data.ageList.map(item => {
          return item.name
        })
        let lineData = data.ageList.map(item => {
          return item.nums
        })
        this.Chart5Data = {
          category: category1,
          lineData: lineData
        };
      });
    },
    changeData() {
      this.chart1Data.femaleNums = this.chart1Data.femaleNums+ 1000;
      this.chart2Data.dayPatNums +=106;
      this.chart2Data.dayPatProcessNums += 98;
      this.chart2Data.dayPatSurplusNums += 1011;
      this.chart2Data.rankingData[0].value +=100;
      this.Chart4Data.barData = [100, 200, 300, 400, 500, 600, 700, 800]
      this.Chart5Data.lineData = [100, 200, 300, 400, 500, 600, 700]
    }
  }
}
</script>

<style lang="scss">
  @import "../styles/index.scss";
</style>
