<template>
  <div class="chartBox">
    <div class="chartTitle">
      <icon class="title_icon" name="chart-pie"></icon>
      <span class="title_font">男女成功率</span>
      <dv-decoration-3 class="dv-dec-3" />
    </div>
    <div ref="Chart1" style="height: 100%"></div>
  </div>
</template>

<script>
export default {
  name: 'Chart1',
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chart1Data: {
      handler(newData) {
        this.option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            textStyle: {
              color: '#fff'
            }
          },
          color:  ['#EE6666', '#5470C6'],
          series: [
            {
              name: '成功数',
              type: 'pie',
              radius: '50%',
              data: [
                { value: newData.femaleNums, name: '女生' },
                { value: newData.maleNums, name: '男生' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              label: {
                color: '#ffffff'
              }
            }
          ]
        };
        this.$nextTick(()=> {
          this.initChart()
        })
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    chart1Data: Object
  },
  mounted() {
    // 图表初始化
    this.initChart();
  },
  methods: {
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.Chart1,'shine');
      // let option = {
      //   tooltip: {
      //     trigger: 'item'
      //   },
      //   legend: {
      //     orient: 'vertical',
      //     left: 'left',
      //     textStyle: {
      //       color: '#fff'
      //     }
      //   },
      //   color:  ['#EE6666', '#5470C6'],
      //   series: [
      //     {
      //       name: '成功数',
      //       type: 'pie',
      //       radius: '50%',
      //       data: [
      //         { value: this.chart1Data.femaleNums, name: '女生' },
      //         { value: this.chart1Data.maleNums, name: '男生' }
      //       ],
      //       emphasis: {
      //         itemStyle: {
      //           shadowBlur: 10,
      //           shadowOffsetX: 0,
      //           shadowColor: 'rgba(0, 0, 0, 0.5)'
      //         }
      //       },
      //       label: {
      //         color: '#ffffff'
      //       }
      //     }
      //   ]
      // };
      // 图表初始化配置
      this.chartInstance.setOption(this.option);    
    }
  }
}
</script>

<style lang="scss" scoped>
.chartBox {
  height: 100%;
  background-color: rgba(19, 25, 47, 0.6);
  border-radius: 10px;
  padding: 10px;
  .chartTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    .title_icon {
      color: #5cd9e8
    }
    .title_font {
      margin: 0 5px;
    }
    .dv-dec-3 {
      width: 80px;
      height: 18px;
    }
  }
}
</style>