<template>
  <div class="chartBox">
    <div class="chartTitle">
      <icon class="title_icon" name="chart-bar"></icon>
      <span class="title_font">近30天处理数量趋势图</span>
    </div>
    <div ref="Chart4" style="height: 100%"></div>
  </div>
</template>

<script>
export default {
  name: 'Chart4',
  data() {
    return {
      option: {}
    }
  },
  props: {
    Chart4Data: Object
  },
  watch: {
    Chart4Data: {
      handler(newData) {
        this.option = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              }
            },
            textStyle: {  //数值样式
              color: '#FFFFFF',   //字体颜色
              fontSize: 12  //字体大小
            }
          },
          legend: {
            data: ["已处理"],
            textStyle: {
              color: "#B4B4B4"
            },
            top: "0%"
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            }
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          series: [
            // {
            //   name: "贯通率",
            //   type: "line",
            //   smooth: true,
            //   showAllSymbol: true,
            //   symbol: "emptyCircle",
            //   symbolSize: 8,
            //   yAxisIndex: 1,
            //   itemStyle: {
            //     normal: {
            //       color: "#F02FC2"
            //     }
            //   },
            //   data: []
            // },
            {
              name: "已处理",
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示数值
                    position: 'top', //数值在上方显示
                    textStyle: {  //数值样式
                      color: '#D1DBFF',   //字体颜色
                      fontSize: 14  //字体大小
                    }
                  },
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#956FD4" },
                    { offset: 1, color: "#3EACE5" }
                  ])
                }
              },
              // data: newData.barData
              data: [450, 609, 654, 890, 604, 329, 300, 408, 777, 424, 588, 688, 234, 577, 435, 268, 665, 564, 465, 765,290, 665, 765, 677, 778, 786, 654]
            }
          ]
        };
        this.$nextTick(() => {
          this.initChart();
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // 图表初始化
    this.initChart();
  },
  methods: {
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.Chart4);
      // 图表初始化配置
      this.chartInstance.setOption(this.option);    
    }
  }
}
</script>

<style lang="scss" scoped>
.chartBox {
  height: 100%;
  background-color: rgba(19, 25, 47, 0.6);
  border-radius: 10px;
  padding: 10px;
  .chartTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    .title_icon {
      color: #5cd9e8
    }
    .title_font {
      margin: 0 5px;
    }
  }
}
</style>