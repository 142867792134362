<template>
  <div class="chartBox">
    <div class="chartTitle">
      <icon class="title_icon" name="chart-area"></icon>
      <span class="title_font">各年龄段数量统计</span>
    </div>
    <div ref="Chart5" style="height: 100%"></div>
  </div>
</template>

<script>
export default {
  name: 'Chart5',
  data() {
    return {
      option: {},
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#f5b44d'
            },
            {
              offset: 1,
              color: '#28f8de'
            }
          ]
        },
        linearGtoB: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#43dfa2'
            },
            {
              offset: 1,
              color: '#28f8de'
            }
          ]
        },
        linearBtoG: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#1c98e8'
            },
            {
              offset: 1,
              color: '#28f8de'
            }
          ]
        },
        areaBtoG: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(35,184,210,.2)'
            },
            {
              offset: 1,
              color: 'rgba(35,184,210,0)'
            }
          ]
        }
      }
    }
  },
  props: {
    Chart5Data: Object
  },
  watch: {
    Chart5Data: {
      handler(newData) {
        this.option = {
          title: {
            text: '',
            textStyle: {
              color: '#D3D6DD',
              fontSize: 24,
              fontWeight: 'normal'
            },
            subtext: [],
            subtextStyle: {
              color: '#fff',
              fontSize: 16
            },
            top: 50,
            left: 80
          },
          tooltip: {
            trigger: 'item'
          },
          xAxis: {
            type: 'category',
            position: 'bottom',
            axisLine: true,
            axisLabel: {
              color: 'rgba(255,255,255,.8)',
              fontSize: 12
            },
            data: newData.category
          },
          // 下方Y轴
          yAxis: {
            name: '',
            nameLocation: 'end',
            nameGap: 24,
            nameTextStyle: {
              color: 'rgba(255,255,255,.5)',
              fontSize: 14
            },
            splitNumber: 4,
            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#fff',
                opacity: 0.1
              }
            },
            axisLabel: {
              color: 'rgba(255,255,255,.8)',
              fontSize: 12
            }
          },
          series: [
            {
              name: '',
              type: 'line',
              smooth: true,
              symbol: 'emptyCircle',
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: '#fff'
                }
              },
              lineStyle: {
                normal: {
                  color: this.colorList.linearBtoG,
                  width: 3
                }
              },
              areaStyle: {
                normal: {
                  color: this.colorList.areaBtoG
                }
              },
              data: newData.lineData,
              lineSmooth: true,
              markLine: {
                silent: true,
                data: [
                  {
                    type: 'average',
                    name: '平均值'
                  }
                ],
                precision: 0,
                label: {
                  normal: {
                    formatter: '平均值: {c}'
                  }
                },
                lineStyle: {
                  normal: {
                    color: 'rgba(248,211,81,.7)'
                  }
                }
              },
              tooltip: {
                position: 'top',
                formatter: '{c}',
                backgroundColor: 'rgba(28,152,232,.2)',
                padding: 6,
                textStyle: {  //数值样式
                  color: '#FFFFFF',   //字体颜色
                  fontSize: 12  //字体大小
                }
              }
            },
            // {
            //   name: '占位背景',
            //   type: 'bar',
            //   itemStyle: {
            //     normal: {
            //       show: true,
            //       color: '#000',
            //       opacity: 0
            //     }
            //   },
            //   silent: true,
            //   barWidth: '50%',
            //   data: newData.weekMaxData,
            //   animation: false
            // }
          ]
        };
        this.$nextTick(() => {
          this.initChart();
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // 图表初始化
    this.initChart();
  },
  methods: {
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.Chart5);
      // 图表初始化配置
      this.chartInstance.setOption(this.option);    
    }
  }
}
</script>

<style lang="scss" scoped>
.chartBox {
  height: 100%;
  background-color: rgba(19, 25, 47, 0.6);
  border-radius: 10px;
  padding: 10px;
  .chartTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    .title_icon {
      color: #5cd9e8
    }
    .title_font {
      margin: 0 5px;
    }
  }
}
</style>